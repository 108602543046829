<template>
  <div id="app">
    <app-header @search="onsearch" />
    <div class="pd-y container">
      <div class="max-width">
        <el-card shadow="never" :body-style="{padding:0}" class="no-border no-radius mb">
          <el-row :gutter="0">
          <el-col :xs="24" :sm="24" :md="6">
              <home-cates />
          </el-col>
          <el-col :xs="24" :sm="24" :md="18">
            <home-banners />
          </el-col>
        </el-row>
        </el-card>
        <home-booths />
        <el-card shadow="never" class="no-border no-radius" :body-style="{padding:0}">
          <home-goods-list></home-goods-list>
        </el-card>
      </div>
    </div>
    <app-footer />
    <fixed-tool></fixed-tool>
  </div>
</template>
<script>
import AppHeader from '@/components/header/header.vue'
import AppFooter from '@/components/footer/footer.vue'
import FixedTool from '@/components/tools/fixed.vue'
import HomeBanners from '@/components/home/banners.vue'
import HomeCates from '@/components/home/cates.vue'
import HomeGoodsList from '@/components/home/goods-list.vue'
import HomeBooths from '@/components/home/booths.vue'
export default {
  components:{
    AppHeader,
    AppFooter,
    FixedTool,
    HomeBanners,
    HomeCates,
    HomeGoodsList,
    HomeBooths,
  },
  methods:{
    onsearch( val ) {
      window.location.href = `/search.html?type=${val.type}&q=${val.keyword}&cate=`
    }
  },
  data() {
    return {
      search_keyword:'',
    }
  }
}
</script>
<style lang="less">
  html,body,#app{
    width:100%;
    height:100%;
    background-color: @body-bg;
    .container{
      min-height:100%!important;
    }
  }
</style>