<template>
        <el-carousel class="banners" trigger="click" v-loading="loading">
                <el-carousel-item v-for="(banner,index) in banners" :key="index" @click.native="jump(banner.href)">
                        <el-image fit="cover" :src="banner.src" class="image"></el-image>
                </el-carousel-item>
        </el-carousel>
</template>
<script>
export default {
        created() {
                this.load_banners();
        },
        methods:{
                jump(href) {
                        window.location.href = href;
                },
                async load_banners() {
                        this.loading = true;
                        let data = await this.$api.home_banners();
                        this.loading = false;
                        if(data) {
                                this.banners = data;
                        }
                }
        },
        data() {
                return {
                        loading:false,
                        banners:[]
                }
        }
}
</script>
<style lang="less" scoped>
        @media screen and (min-width: 768px) {
                .banners{
                        background-color: #eee;
                        height:400px;
                        /deep/
                        .el-carousel__container{
                                height:400px;
                        }
                        .image{
                                cursor: pointer;
                                width:100%;
                                height:100%;
                        }
                }
        }
        @media screen and (max-width: 768px) {
                .banners{
                        background-color: #eee;
                        height:180px;
                        /deep/
                        .el-carousel__container{
                                height:180px;
                        }
                        .image{
                                cursor: pointer;
                                width:100%;
                                height:100%;
                        }
                }
        }
</style>