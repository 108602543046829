<template>
        <div class="home-goods" v-loading="loading">
                <el-row :gutter="10">
                        <el-col :xs="12" :sm="8" :md="6" v-for="(item,index) in list" :key="index">
                                <good-item-square :item="item" @click="click" @factory-click="factoryClick"></good-item-square>
                        </el-col>
                </el-row>
                <div class="pd-y text-center">
                        <el-pagination background :page-count="page_count" :current-page.sync="page" @current-change="load_list">

                        </el-pagination>
                </div>
        </div>
</template>
<script>
import GoodItemSquare from '@/components/goods/good-item-square.vue'
export default {
        components:{
                GoodItemSquare
        },
        data() {
                return {
                        loading:false,
                        page_count:0,
                        page:1,
                        list:[],
                }
        },
        methods:{
                click( item ) {
                        window.location.href = `/good.html?id=${item.id}&from=home`
                },
                factoryClick(item) {
                        window.location.href = `/factory.html?id=${item.factory.id}&from=home`
                },
                async load_list() {
                        this.loading = true;
                        let data = await this.$api.home_goods({
                                page:this.page,
                        })
                        this.loading = false;
                        if(data) {
                                this.page_count = data.page_count;
                                this.page = data.page;
                                this.list = data.list;
                        }
                }
        },
        created() {
                this.load_list()
        }
}
</script>
<style lang="less" scoped>
        
</style>