import Vue from 'vue'
import App from './index.vue'
import 'normalize.css'
import '../../assets/element-theme.css'
import store from '../../store/index.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/display.css';
import api from '@/api'
Vue.prototype.$api = api;
Vue.use(ElementUI);
Vue.config.productionTip = false

//Antd
import {Affix,Empty} from 'ant-design-vue'
Vue.use(Affix)
Vue.use(Empty)

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')