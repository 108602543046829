<template>
        <div class="cates" v-loading="loading">
                <div class="cate-item" @click="toSearch('')">
                        <div class="name">所有商品</div>
                        <div class="icon">
                                <i class="el-icon-arrow-right"></i>
                        </div>
                </div>
                <div class="cate-item" v-for="(item,index) in cates" :key="index" @click="toSearch(item.id)">
                        <div class="name">
                                {{item.name}}
                        </div>
                        <div class="icon">
                                <i class="el-icon-arrow-right"></i>
                        </div>
                </div>
        </div>
</template>
<script>
export default {
        created() {
                this.load_cates()
        },
        methods:{
                toSearch( id ) {
                        window.location.href = `/search.html?type=goods&q=&cate=${id}`
                },
                async load_cates() {
                        this.loading = true;
                        let data = await this.$api.cate_list();
                        this.loading = false;
                        if(data) {
                                this.cates = data
                        }
                }
        },
        data() {
                return {
                        loading:false,
                        cates:[]
                }
        }
}
</script>
<style lang="less" scoped>
        @media screen and (max-width: 768px) {
                .cates{
                        >.cate-item{
                                cursor: pointer;
                                display: inline-block;
                                padding:@pd-md;
                                >.name{
                                        display: inline-block;
                                }
                                >.icon{
                                        display: none
                                }
                                &:hover{
                                        background-color: @primary-color;
                                        color:#fff;
                                }
                        }
                }
        }
        @media screen and (min-width: 768px) {
                .cates{
                        height:400px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        >.cate-item{
                                cursor: pointer;
                                display: flex;
                                padding:@pd-md @pd-lg;
                                >.name{
                                        flex:1;
                                }
                                >.icon{
                                        padding:0 @pd-md;
                                }
                                &:hover{
                                        background-color: @primary-color;
                                        color:#fff;
                                }
                        }
                }
        }
</style>