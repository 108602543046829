<template>
        <el-row :gutter="10" v-if="list.length>0" class="booths">
          <el-col :xs="24" :sm="12" :md="8" v-for="(item,index) in list" :key="index">
            <el-card :body-style="{padding:0}" shadow="never" class="no-border no-radius mb">
              <el-image @click="jump(item.href)" class="image" :src="item.src"></el-image>
            </el-card>
          </el-col>
        </el-row>
</template>
<script>
export default {
        data() {
                return {
                        loading:false,
                        list:[],
                }
        },
        created() {
                this.load_booths()
        },
        methods:{
                jump(href) {
                        window.location.href = href
                },
                async load_booths() {
                        let data = await this.$api.home_booth();
                        this.list = data;
                }
        }
}
</script>
<style lang="less" scoped>
        .booths{
                .image{
                        cursor: pointer;
                        vertical-align: top;
                        width:100%;
                        height:auto;
                }
        }
</style>